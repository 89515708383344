import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    ApiKey,
    ApiUrl,
    TransactionType,
    BankingChannel,
    PanelType,
    FixedDepositAmount
} from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    createMultiPartFormBody,
    numberWithCurrencyFormat,
    numberWithCurrencyFormat2,
    numberWithCommas

} from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { Modal, ModalBody, Alert } from "reactstrap";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Numpad from "../../components/custom/Numpad";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import BlueWarningIcon from "../../assets/img/v3/notice_blue.svg";
import Spinner from 'react-bootstrap/Spinner';
import TickIcon from "../../assets/img/assets/tick.svg";
import TickIcon1 from "../../assets/img/assets/tick_1.svg";
import CopyIcon from "../../assets/img/v3/copyIcon2.svg"
import CompleteIcon from "../../assets/img/v3/complete_Icon.gif"
import CompleteIcon2 from "../../assets/img/v3/complete_Icon.gif"
import CompleteIcon3 from "../../assets/img/v3/complete_Icon.gif"
import classnames from "classnames";

/// <summary>
/// Author: -
/// </summary>
const NormalDeposit = (props) => {
    const { t, i18n } = useTranslation();
    let _userData = useSelector((state) => state.authState.userData);
    let _dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        errors,
        clearErrors,
        setError
    } = useForm();


    const [bankAccountOption, setBankAccountOption] = useState([]);
    const [bankAccountId, setBankAccountId] = useState("");
    const [receiptImage, setReceiptImage] = useState();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [isReloadSuccess, setIsReloadSuccess] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);
    const [reloadDate, setReloadDate] = useState();
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [currentInput, setCurrentInput] = useState();
    const [imageFileSize, setImageFileSize] = useState(0);
    const ONE_MB_IN_BYTES = 1048576;
    const fileSizeLimit_InMB = 20;
    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const _ALERT_TIMEOUT = 3000;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [bankIsSelected, setBankIsSelected] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});
    const [amountValue, setAmountValue] = useState(0);
    const [displayAmountValue, setDisplayAmountValue] = useState("0.00");
    const fixedAmount = FixedDepositAmount;
    const [showBankNameAlert, setShowBankNameAlert] = useState(false);
    const [showAccountNumberAlert, setShowAccountNumberAlert] = useState(false);
    const [showHolderNameAlert, setShowHolderNameAlert] = useState(false);

    const [tngSetting, setTngSetting] = useState({});
    const [tngStatus, setTngStatus] = useState(false);
    const [tngImg, setTngImg] = useState("");
    const [depositReference, setDepositReference] = useState("");
    const [canDisplayBank, setCanDisplayBank] = useState(true);
    const [showNumpad, setShowNumpad] = useState(false);
    const [numpadStr, setNumpadStr] = useState("");
    const [bankReference, setBankReference] = useState("");
    const [successCopy, setSuccessCopy] = useState(0);
    const keyboard = useRef();

    //useEffect

    useEffect(() => {
        init();
    }, []);


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    async function init() {
        getBankAccount();
        getTng();
        setBankReference(generateReferenceCode());
    }

    async function getBankAccount() {
        let responseJson = await ApiEngine.get(
            ApiUrl._API_GET_BANK_ACCOUNT +
            "?deposit=" +
            true
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBankAccountOption(responseJson[ApiKey._API_DATA_KEY].sort((a, b) => a.bankNormalRank - b.bankNormalRank));
            if (responseJson[ApiKey._API_DATA_KEY].length <= 0) {
                props.setDisplayNormalDeposit(false);
            }
        }
    }

    async function getTng() {
        var TNG_START_HOUR_LIMIT_IN_UTC0 = 0;
        var TNG_END_HOUR_LIMIT_IN_UTC0 = 23;


        let TnGresponseJson = await ApiEngine.get(
            ApiUrl._API_GET_COMPANY_TNG_SYSTEM_SETTINGS
        );
        let startTimeStr = "";
        let endTimeStr = "";
        let TnGStatus = false;

        if (TnGresponseJson[ApiKey._API_SUCCESS_KEY] && TnGresponseJson[ApiKey._API_DATA_KEY].length > 0) {
            let data = TnGresponseJson[ApiKey._API_DATA_KEY];

            data.map((keyValuePair, index) => {
                if (keyValuePair["key"] === "TnGStartTime") {
                    startTimeStr = keyValuePair["value"];
                    TNG_START_HOUR_LIMIT_IN_UTC0 = moment(
                        moment().format("YYYY-MM-DD") + " " + startTimeStr,
                        "YYYY-MM-DD HH:mm:ss"
                    );
                }
                if (keyValuePair["key"] === "TnGEndTime") {
                    endTimeStr = keyValuePair["value"];
                    TNG_END_HOUR_LIMIT_IN_UTC0 = moment(
                        moment().format("YYYY-MM-DD") + " " + endTimeStr,
                        "YYYY-MM-DD HH:mm:ss"
                    );
                }
                if (keyValuePair["key"] === "TnGStatus") {
                    TnGStatus = keyValuePair["value"] === "true";
                }
            });
        }

        if (
            moment(TNG_END_HOUR_LIMIT_IN_UTC0).isBefore(
                TNG_START_HOUR_LIMIT_IN_UTC0
            )
        ) {
            TNG_END_HOUR_LIMIT_IN_UTC0 = moment(TNG_END_HOUR_LIMIT_IN_UTC0).add(
                1,
                "day"
            );
        }

        if (
            TnGStatus &&
            moment().isAfter(TNG_START_HOUR_LIMIT_IN_UTC0) &&
            moment().isBefore(TNG_END_HOUR_LIMIT_IN_UTC0)
        ) {
            setTngStatus(true);
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_USER_BANK + "?type=TNG"
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setTngSetting(responseJson[ApiKey._API_DATA_KEY]);
                let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=DailyQR";
                let TnGJson = await ApiEngine.get(apiUrl);
                if (
                    TnGJson[ApiKey._API_SUCCESS_KEY] &&
                    TnGJson[ApiKey._API_DATA_KEY] != null
                ) {
                    let data = TnGJson[ApiKey._API_DATA_KEY];
                    setTngImg(data);
                }
            }

            let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=depositReference";
            let settingJson = await ApiEngine.get(apiUrl);
            if (settingJson[ApiKey._API_SUCCESS_KEY] && settingJson[ApiKey._API_DATA_KEY] != null) {
                let data = settingJson[ApiKey._API_DATA_KEY];
                setDepositReference(data);
            }
        }
    }




    /// <summary>
    /// Author : -
    /// Used to set logo
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            const fileSizeInMB = file.size / ONE_MB_IN_BYTES;
            setImageFileSize(fileSizeInMB);
            setValue("receipt", file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setReceiptImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const remove = () => {
        setReceiptImage("");
        setValue("receipt", null);
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function resetFormField() {
        setBankAccountOption([]);
        setValue("bankId", "");
        setValue("bankAccountId", "");
        setValue("receipt", null);
        setReceiptImage("");
        resetAmountValue();
        setValue("depositDateTime", moment().format("YYYY-MM-DD HH:mm:ss"));
    }

    const checkValueOnSelectFixedAmt = async (fixedAmt) => {
        let updatedValue = parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue);
        updatedValue = (Math.trunc(updatedValue * 100) / 100).toFixed(2);
        if (updatedValue > max) {
            setAmountValue(
                max + ".00"
            );
            setDisplayAmountValue(max + ".00");
            setNumpadStr((max * 100).toString());
        }
        else {
            setAmountValue(
                updatedValue
            );
            setDisplayAmountValue(updatedValue);
            setNumpadStr((updatedValue * 100).toString());
        }

        clearErrors();
        let tempAmountValue = updatedValue;
        if (parseFloat(tempAmountValue) < min) {
            setError("amount", {
                message: "VALUE_LESS_THAN_MINIMUM",
            });
        }
        else if (parseFloat(tempAmountValue) > max) {
            setError("amount", {
                message: "EXCEED_MAXIMUM_VALUE",
            });
        }
    }



    async function onSubmit(data, e) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "set-busy",
                    isBusy: true
                })
            );
        } else {
            _dispatch(setBusy());
        }
        let params = {
            transactionTypeId: TransactionType._DEPOSIT,
            amount: parseFloat(amountValue),
            channel: BankingChannel._CHANNEL_OFFLINE_BANKING,
            panel: PanelType._MEMBERSITE,
            bankAccountId: bankAccountId,
            receiptReference: depositReference,
            referenceCode: bankReference
        };

        if (!stringIsNullOrEmpty(data.receipt)) {
            params["receipt"] = data.receipt;
        }

        if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
            params["bonusId"] = props.bonusDeposit;
        } else if (props.bonusDeposit === "-1") {
            params["bonusId"] = "";
        }

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_TRANSACTION,
            createMultiPartFormBody(params)
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            setPaidAmount(params.amount);
            setIsReloadSuccess(true);
            setReloadDate(moment().format("DD MMM YYYY, hh:mm A"));
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY]);
            props.checkPendingTxn();
            e.target.reset();
            resetFormField();
            //if (window.ReactNativeWebView) {
            //  window.ReactNativeWebView.postMessage(
            //    JSON.stringify({
            //      action: "set-busy",
            //      isBusy: false
            //    })
            //  );
            //}
        } else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    t(responseJson[ApiKey._API_MESSAGE_KEY])
                )
            );
        }
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    action: "set-busy",
                    isBusy: false
                })
            );
        } else {
            _dispatch(setIdle());
        }
    }

    const handleModalOnClick = () => {
        setIsReloadSuccess(false);
        //props.parentInit();
        init();
    };

    function setMinMaxButton(min, max, bankAccountId) {
        setMin(min);
        setMax(max);
        setBankAccountId(bankAccountId);
    }

    //function copyText(label) {
    //  navigator.clipboard.writeText(label);
    //  document.execCommand("copy");
    //  setShowCopyAlert(true);
    //  setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
    //  }

    function copyText(label, type) {
        navigator.clipboard.writeText(label);
        document.execCommand("copy");
        setShowCopyAlert(true);

        if (type === "bank_name") {
            setShowBankNameAlert(true);
            setShowAccountNumberAlert(false);
            setShowHolderNameAlert(false);
            setTimeout(() => setShowBankNameAlert(false), [5000]);
        }
        else if (type === "account_number") {
            setShowBankNameAlert(false);
            setShowAccountNumberAlert(true);
            setShowHolderNameAlert(false);
            setTimeout(() => setShowAccountNumberAlert(false), [5000]);
        }
        else if (type === "holder_name") {
            setShowBankNameAlert(false);
            setShowAccountNumberAlert(false);
            setShowHolderNameAlert(true);
            setTimeout(() => setShowHolderNameAlert(false), [5000]);
        }

        setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);



    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };


    const handleBankSelection = async (bankAccount) => {
        setBankIsSelected(true);
        setSelectedBank(bankAccount);
        setMinMaxButton(bankAccount.minimumDeposit, bankAccount.maximumDeposit, bankAccount.id)
    }

    function onKeyPress(key) {
        if (key != "{bksp}") {
            let tempDisplayAmountValue = displayAmountValue;
            if (tempDisplayAmountValue === "0.00" && key == "0") {
                //do nothing
            }
            else {
                let tempNumpadStr = numpadStr;
                tempNumpadStr += key;
                console.log(numpadStr);
                let temp = tempNumpadStr.substring(0, tempNumpadStr.length - 2) + "." + tempNumpadStr.substring(tempNumpadStr.length - 2, tempNumpadStr.length);
                if (temp > max) {
                    tempDisplayAmountValue = max + ".00";
                }
                else {
                    setNumpadStr(tempNumpadStr);
                    if (tempNumpadStr.length <= 2) {
                        tempDisplayAmountValue = "0." + tempNumpadStr;
                    }
                    else {
                        tempDisplayAmountValue = tempNumpadStr.substring(0, tempNumpadStr.length - 2) + "." + tempNumpadStr.substring(tempNumpadStr.length - 2, tempNumpadStr.length);
                    }
                }
                setDisplayAmountValue(tempDisplayAmountValue);
                if (tempDisplayAmountValue > min) {

                    let tempAmountValue = parseFloat(tempDisplayAmountValue);
                    if (isNaN(tempAmountValue)) {
                        tempAmountValue = 0;
                    }
                    checkMinMax(tempAmountValue);
                    setAmountValue(parseFloat(tempAmountValue));
                }

            }
        }
        else {
            resetAmountValue();
        }
    }

    function checkMinMax(amount) {
        clearErrors();
        if (parseFloat(amount) < min) {
            setError("amount", {
                message: "VALUE_LESS_THAN_MINIMUM",
            });
        }
        else if (parseFloat(amount) > max) {
            setError("amount", {
                message: "EXCEED_MAXIMUM_VALUE",
            });
        }
    }

    function resetAmountValue() {
        setAmountValue(0);
        setDisplayAmountValue("0.00");
        setNumpadStr("");
    }

    function generateReferenceCode() {
        // Get current Unix timestamp (seconds since Jan 1, 1970)
        const unixTimestamp = Math.floor(Date.now() / 1000);

        // Generate a random number between 1 and 4 (inclusive)
        const randomNumber = Math.floor(Math.random() * 4) + 1;

        const referenceCode = unixTimestamp * randomNumber;

        // Combine the parts with spaces
        return `${referenceCode}`;
    }

    useEffect(() => {
        if (Object.keys(props.memberNormalDepositTransactionHistory).length > 0 && bankAccountOption.length > 0) {
            checkNormalDeposit();
        }
    }, [props.memberNormalDepositTransactionHistory, bankAccountOption]);

    //const checkNormalDeposit = async () => {
    //    const data = props.memberNormalDepositTransactionHistory;
    //    handleBankSelection(bankAccountOption.filter(i => i.bankName == data.bankName && i.accountNumber == data.accountNumber)[0]);
    //  }
    const checkNormalDeposit = async () => {
        const data = props.memberNormalDepositTransactionHistory;
        if (data.bankName == "TNG") {
            if (tngStatus) {
                const option = bankAccountOption.filter(i => i.bankName == data.bankName && i.accountNumber == data.accountNumber)[0];
                if (typeof option !== "undefined") {
                    handleBankSelection(option);
                }
            }
        } else {
            const option = bankAccountOption.filter(i => i.bankName == data.bankName && i.accountNumber == data.accountNumber)[0];
            if (typeof option !== "undefined") {
                handleBankSelection(option);
            }
        }
    }

    return (
        <>
            <form className="deposit-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group m-b-0">
                    <div className="row">
                        <div className="col-12">
                            {bankAccountOption && bankAccountOption.length === 0 && (<div style={{ textAlign: "center" }}>
                                <Spinner animation="border" role="status" size="sm"></Spinner>
                            </div>)}
                            {bankIsSelected === false && (
                                <>
                                    <div className="all-normal-banks-container">
                                        {bankAccountOption.filter(x => x.bankName !== "TNG") && bankAccountOption.filter(x => x.bankName !== "TNG").length > 0 && (
                                            <>
                                                <div className="normal-bank-item-container">
                                                    <div className="font14 normal-parent-bank-title">
                                                        {t("BANK")}
                                                    </div>
                                                    <div className="deposit-normal-bank-list-container mt-3">
                                                        {bankAccountOption.filter(x => x.bankName !== "TNG").map((child, index) => {
                                                            return (
                                                                <div
                                                                    key={"normal-deposit-bank" + index}
                                                                    className="deposit-normal-bank-list-items"
                                                                    onClick={() => { handleBankSelection(child) }}
                                                                >
                                                                    <img
                                                                        src={child.bankImg2}
                                                                        alt="normal payment banks"
                                                                        className="img-responsive"
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                                <hr className="custom-hr" />
                                            </>
                                        )}
                                        {tngStatus && bankAccountOption.filter(x => x.bankName === "TNG") && bankAccountOption.filter(x => x.bankName === "TNG").length > 0 && (
                                            <>
                                                <div className="normal-bank-item-container">
                                                    <div className="font14 normal-parent-bank-title">
                                                        {t("DuitNow") + " / " + t("TNG")}
                                                    </div>
                                                    <div className="deposit-normal-bank-list-container mt-3">

                                                        <div
                                                            className="deposit-normal-bank-list-items"
                                                            onClick={() => { handleBankSelection(bankAccountOption.filter(x => x.bankName === "TNG").pop()) }}
                                                        >
                                                            <img
                                                                src={bankAccountOption.filter(x => x.bankName === "TNG").pop().bankImg2}
                                                                alt="normal payment banks"
                                                                className="img-responsive"
                                                                style={{ height: "100%" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                            {bankIsSelected && (
                                <>
                                    <div className="font14 mb-3">
                                        {t("SELECTED_BANK_METHOD")}
                                    </div>
                                    <div className="instant-provider-selected-wrapper-orange mt-3" onClick={() => { setBankIsSelected(false); setSelectedBank({}); }}>
                                        <div className="instant-provider active">
                                            <img className="tick-image" src={TickIcon1} alt="active" />

                                            <img className="instant-provider-image" src={selectedBank.bankImg2} alt={selectedBank.bankName} />

                                        </div>
                                    </div>

                                    <hr className="custom-hr" />
                                    {selectedBank.bankName === "TNG" && <>

                                        <div className="font14 mb-3">
                                            {t("TNG_QR")}
                                        </div>
                                        <div className="form-group m-b-15 text-center pt-4">
                                            <img src={tngImg} width="50%" alt="tng-qr" />
                                        </div>
                                        <div className="form-group m-b-15 text-center">
                                            <a
                                                href="https://youtu.be/ertSjk2cF7g"
                                                target="_blank"
                                                style={{ textDecoration: "underline" }}
                                            >
                                                {t("HOW_TO_TNG")}
                                            </a>
                                        </div>
                                        <hr className="custom-hr" />
                                    </>}
                                    <div className="font14 mb-3">
                                        {t("BANK_ACCOUNT_DETAILS") + " (" + selectedBank.bankName + ")"}
                                    </div>
                                    {bankAccountOption &&
                                        bankAccountOption.length > 0 &&
                                        bankIsSelected &&
                                        <div className="deposit-normal-bank-details-container mt-3 mb-4">
                                            <div className="deposit-normal-bank-details-item normal-deposit-mt-2">
                                                <div className="first">
                                                    {t("BANK_NAME")}
                                                </div>
                                                <div className="second">
                                                    <div>
                                                        {selectedBank.bankName}
                                                    </div>
                                                    <div className="copy" onClick={() => copyText(selectedBank.bankName, "bank_name")}>
                                                        <img
                                                            src={showBankNameAlert ? CompleteIcon : CopyIcon}
                                                            alt="i8 banks"
                                                            className="normal-deposit-img-width"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedBank.bankName !== "TNG" && (
                                                <>
                                                    <div className="deposit-normal-bank-details-item normal-deposit-mt-2">
                                                        <div className="first">
                                                            {t("BANK_ACCOUNT_NUMBER")}
                                                        </div>
                                                        <div className="second">
                                                            <div>
                                                                {selectedBank.accountNumber}
                                                            </div>
                                                            <div className="copy" onClick={() => copyText(selectedBank.accountNumber, "account_number")}>
                                                                <img
                                                                    src={showAccountNumberAlert ? CompleteIcon2 : CopyIcon}
                                                                    alt="i8 banks"
                                                                    className="normal-deposit-img-width"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="deposit-normal-bank-details-item normal-deposit-mt-2">
                                                        <div className="first">
                                                            {t("BANK_ACCOUNT_HOLDER_NAME")}
                                                        </div>
                                                        <div className="second">
                                                            <div>
                                                                {selectedBank.accountHolderName}
                                                            </div>
                                                            <div className="copy" onClick={() => copyText(selectedBank.accountHolderName, "holder_name")}>
                                                                <img
                                                                    src={showHolderNameAlert ? CompleteIcon3 : CopyIcon}
                                                                    alt="i8 banks"
                                                                    className="normal-deposit-img-width"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="deposit-normal-bank-details-item normal-deposit-mt-2">
                                                <div className="first">
                                                    {t("MIN")}/{t("MAX")} {t("DEPOSIT")}
                                                </div>
                                                <div className="second">
                                                    MYR {numberWithCommas(selectedBank.minimumDeposit)} - MYR {numberWithCommas(selectedBank.maximumDeposit)}
                                                </div>
                                            </div>
                                            <div className="deposit-normal-bank-details-item normal-deposit-mt-2">
                                                <div className="first">
                                                    {t("TRANSFER_REFERENCE")}
                                                </div>
                                                <div className="second">
                                                    <div>
                                                        {bankReference}
                                                    </div>
                                                    <div className="copy" onClick={() => copyText(bankReference)}>
                                                        <img
                                                            src={showHolderNameAlert ? CompleteIcon3 : CopyIcon}
                                                            alt="i8 banks"
                                                            className="normal-deposit-img-width"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group m-b-0">
                                        <label className="col-form-label">
                                            <b>{t("AMOUNT")} </b>
                                        </label>
                                        <div className="deposit-input-group mb-3">
                                            <span className="currency-prefix">MYR</span>
                                            <input
                                                type="number"
                                                readOnly
                                                className="form-control bg-white amount-text"
                                                //   placeholder={t("PLEASE_ENTER_AMOUNT")}
                                                placeholder={amountValue}
                                                value={amountValue}
                                                name="amount"
                                                style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
                                                onClick={() => {
                                                    setShowNumpad(true);
                                                }}
                                                ref={register({
                                                    required: "PLEASE_ENTER_AMOUNT",
                                                    validate: {
                                                        minValue: (value) =>
                                                            (!canDisplayBank
                                                                ? value >= min
                                                                : parseFloat(value) > 0 && value >= min) ||
                                                            "VALUE_LESS_THAN_MINIMUM",
                                                        maxValue: (value) =>
                                                            (!canDisplayBank ? true : parseFloat(value) <= max) ||
                                                            "EXCEED_MAXIMUM_VALUE",
                                                    },
                                                })}
                                            />

                                            <div className="close-button">
                                                <Icon
                                                    path={mdiClose}
                                                    size={1}
                                                    color="#FF0808"
                                                    onClick={() => {
                                                        resetAmountValue();
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {errors.amount && (
                                            <div className="invalid-feedback">{t(errors.amount.message)}</div>
                                        )}

                                        <div className="col-md-12 font-11">
                                            {parseFloat(amountValue) >= min ?
                                                <>
                                                    <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                                                    <span>{numberWithCurrencyFormat(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                                                    <i className="fas fa-arrow-right" />
                                                    <span>{" " + numberWithCurrencyFormat(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue) : 0), 2, true)}</span>
                                                </>
                                                :
                                                <>
                                                </>
                                            }
                                        </div>

                                        <div className="col-md-12">
                                            {min >= 0 && max >= 0 && (
                                                <div className="font-11 amount-limit-reminder">
                                                    <span className="reminder mr-2">
                                                        * {t("MIN")} : MYR {numberWithCommas(min)} / {t("MAX")} : MYR {numberWithCommas(max)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        <div className="amount-row">
                                            {fixedAmount.map((amount, index) => {
                                                return (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        onClick={() => {
                                                            checkValueOnSelectFixedAmt(amount);
                                                        }}
                                                        className="btn custom-button"
                                                    >
                                                        + {numberWithCommas(amount)}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="withdrawal-name-alert alert alert-warning">
                                        <div className="withdrawal-warning-text">
                                            <img className="blue-warning-icon" src={BlueWarningIcon} />
                                            <span class="text-yellow-lighter"><b>{t("FRINEDLY_REMINDER")}</b></span>{t("DEPOSIT_RECEIPT_REFERENCE")}
                                        </div>
                                    </div>
                                    <div className="form-group m-b-15">
                                        <label className="col-form-label">
                                            <b>{t("RECEIPT")}</b>
                                            {" (" + t("MAX_FILE_SIZE") + ": " + fileSizeLimit_InMB + "MB)"}
                                        </label>
                                        <div>
                                            <Controller
                                                control={control}
                                                name="receipt"
                                                defaultValue={""}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropzone
                                                            accept={"image/*"}
                                                            onDrop={(acceptedFiles) => imageDrop(acceptedFiles)}
                                                            maxSize={fileSizeLimit_InMB * ONE_MB_IN_BYTES}
                                                            onDropRejected={() =>
                                                                _dispatch(
                                                                    showResponseMessage(
                                                                        false,
                                                                        t("FILE_LARGER_THAN") +
                                                                        fileSizeLimit_InMB +
                                                                        "MB, " +
                                                                        t("PLEASE_TRY_AGAIN")
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div>
                                                                    <section style={{ position: "relative" }}>
                                                                        {!stringIsNullOrEmpty(receiptImage) && (
                                                                            <div
                                                                                style={{
                                                                                    background: "transparent",
                                                                                    padding: "5px 10px",
                                                                                    textAlign: "center",
                                                                                    position: "absolute",
                                                                                    right: "5px",
                                                                                    top: "5px",
                                                                                }}
                                                                                onClick={() => remove()}
                                                                            >
                                                                                <i className="fa fa-trash text-red"></i>
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            className="dropzone"
                                                                            style={{ minHeight: "200px", textAlign: "center" }}
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} />
                                                                            {stringIsNullOrEmpty(receiptImage) && (
                                                                                <h4 style={{ color: "grey" }}>
                                                                                    {t(
                                                                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                                                                    )}
                                                                                </h4>
                                                                            )}
                                                                            {!stringIsNullOrEmpty(receiptImage) && (
                                                                                <aside className="thumbsContainer">
                                                                                    <div className="thumb">
                                                                                        <div className="thumbInner">
                                                                                            <img
                                                                                                src={receiptImage}
                                                                                                className="dropzone-img"
                                                                                                alt="dropzone-img"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </aside>
                                                                            )}
                                                                        </div>
                                                                    </section>
                                                                    {!stringIsNullOrEmpty(receiptImage) ? (
                                                                        <div style={{ color: "#002e6c", fontSize: "15px" }}>
                                                                            {t("UPLOADED_FILE_SIZE") +
                                                                                ": " +
                                                                                numberWithCurrencyFormat(imageFileSize, 2, true) +
                                                                                "MB"}
                                                                        </div>
                                                                    ) : (
                                                                        <div>&nbsp;</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <button disabled={amountValue < min} type="submit" className="btn btn-block btn-brand-gradient">
                                        {t("SUBMIT")}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            <Modal
                fade={false}
                contentClassName="modal-brand modal-center"
                id="modal-center"
                isOpen={isReloadSuccess}
                toggle={() => {
                    setIsReloadSuccess(false);
                }}
                centered
            >
                <ModalBody>
                    <div className="modal-custom-header justify-content-center" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "29px",
                        color: "#EFEFEF"
                    }}>
                        <h2
                            className="mb-0"
                        >
                            {t("RELOAD_SUCCESS")}
                        </h2>
                        <div className="text-center mt-4">
                            <img
                                src={require("../../assets/img/v3/approved-modal.png")}
                                alt={t("APPROVED")}
                            />
                        </div>
                        <div className="text-center mt-4">
                            {t("PAID_DEPOSIT", { amount: paidAmount })}
                        </div>
                    </div>
                    <table className="table text-brand table-reload-detail text-white">
                        <tr>
                            <th>{t("DEPOSIT_DATE_TIME")}</th>
                            <th style={{ textAlign: "right" }}>{reloadDate}</th>
                        </tr>
                        <tr>
                            <th>{t("REFERENCE_NUMBER")}</th>
                            <th style={{ textAlign: "right" }}>{transcactionNumber}</th>
                        </tr>
                    </table>
                    <button
                        className="btn btn-block btn-brand-gradient"
                        onClick={() => {
                            setIsReloadSuccess(false);
                        }}
                    >
                        {t("DONE")}
                    </button>
                </ModalBody>
            </Modal>
            {/*<Numpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          setAmountValue(currentInput.value);
          setCurrentInput(e);
        }}
      />*/}
            <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad  popup-transfer-numpad" backdrop={true} toggle={() => { setShowNumpad(false); }} isOpen={showNumpad} centered>
                <ModalBody>
                    <div className="display-wrapper">
                        <button type="button" style={{
                            alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
                        }} onClick={() => { setShowNumpad(false); }} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
                        <strong className="text-brand display-value">{displayAmountValue}</strong>
                        <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("CURRENT_BALANCE")} {isNaN(parseFloat(_userData.walletBalance)) ? "0.00" : numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true)/*parseFloat(props.currentBalance).toFixed(3)*/}</div>
                    </div>
                    <Keyboard
                        layout={{
                            'default': [
                                "1 2 3 4 5 6 7 8 9 0 {bksp}"
                            ]
                        }}
                        display={{
                            '{bksp}': "<i class='fas fa-backspace'></i>",
                        }}
                        theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
                        keyboardRef={r => (keyboard.current = r)}
                        onKeyPress={onKeyPress}
                        disableButtonHold
                        disableCaretPositioning
                    />

                    <button disabled={amountValue < min} className="btn btn-block btn-brand-gradient btn-done" onClick={() => { setShowNumpad(false); }}>{t("DONE")}</button>
                </ModalBody>
            </Modal>

            {showCopyAlert && (
                <div
                    className="content content-full-width"
                    style={{
                        zIndex: "9999",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "200px",
                    }}
                >
                    <Alert color="green" style={{ textAlign: "center" }}>
                        {t("COPY_SUCCESS")}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default NormalDeposit;
