import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  FixedDepositAmount
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCurrencyFormat, numberWithCurrencyFormat2, numberWithCommas } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import DepositNumpad from "../../components/custom/DepositNumpad";
import { Alert } from "reactstrap";
import classnames from "classnames";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Spinner from 'react-bootstrap/Spinner';
import { Modal, ModalBody } from 'reactstrap';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

//myr deposit image
import VaderPayIcon from "../../assets/img/assets/VaderPay.svg";
import VaderPayQRIcon from "../../assets/img/assets/VaderPayQR.svg";
import SPGIcon from "../../assets/img/assets/SPG.svg";
import SPGQRIcon from "../../assets/img/assets/SPGQR.svg";
/*import FattyPayIcon from "../../assets/img/assets/FattyPay.svg";*/
import FattyPayIcon from "../../assets/img/assets/FPX_APP.svg";
import FattyPayQRIcon from "../../assets/img/assets/TruePay.svg";
import PowerPayIcon from "../../assets/img/assets/PowerPay.png";
import DuitNowIcon from "../../assets/img/assets/DuitNowQR.svg";
import PayTrustIcon from "../../assets/img/assets/PayTrust.svg";

import BigPayIcon from "../../assets/img/assets/BigPay.svg";
import BoostPayIcon from "../../assets/img/assets/Boost.svg";
import GrabPayIcon from "../../assets/img/assets/GrabPay.svg";
import MAEIcon from "../../assets/img/assets/MAE.svg";
import DirePayIcon from "../../assets/img/assets/direPay.svg";
import ShopeePayIcon from "../../assets/img/assets/shopeePay.svg";
import TngIcon from "../../assets/img/assets/tng_logo.png";
import EeziePayIcon from "../../assets/img/assets/EeziePay.svg";
import EeziePayQRIcon from "../../assets/img/assets/EeziePay.svg";

//other image
import TickIcon from "../../assets/img/assets/tick.svg";
import TickIcon1 from "../../assets/img/assets/tick_1.svg";

/// <summary>
/// Author: -
/// </summary>
const InstantDeposit = (props) => {
  const { t } = useTranslation();
  let _userData = useSelector((state) => state.authState.userData);
  let _dispatch = useDispatch();
  const { handleSubmit, register, errors, clearErrors, setError } =
    useForm();
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bankIsSelected, setBankIsSelected] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const fixedAmount = FixedDepositAmount;
  const [instantPaymentList, setInstantPaymentList] = useState([]);
  const [loadingPaymentData, setLoadingPaymentData] = useState(true);
  const [paymentOptionList, setPaymentOptionList] = useState([]);
  const [selectedProviderName, setSelectedProviderName] = useState("");
  const [amountValue, setAmountValue] = useState(0);
  const [displayAmountValue, setDisplayAmountValue] = useState("0.00");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [vaderBankList, setVaderBankList] = useState([]);
  const [currentInput, setCurrentInput] = useState();
  const [canDisplayBank, setCanDisplayBank] = useState(true);
  const [showNumpad, setShowNumpad] = useState(false);

  const keyboard = useRef();

  //useEffect 
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (props.memberTransactionHistory.length > 0 && paymentOptionList.length > 0) {
      initMemberTransactionHistoryData();
    }
  }, [paymentOptionList, props.memberTransactionHistory]);

  //init/call function
  async function init() {
    getPaymentList();
  }


  const getPaymentList = async () => {
    let memberJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_DETAILS_BY_ID);
    var responseGetVaderBankJson = await ApiEngine.get(ApiUrl._API_GET_VADERPAY_BANK_OPTION);
    let responseJsonBank = await ApiEngine.get(
      ApiUrl._API_GET_BANK_LIST
    );


    let bankList = [];
    let providerOptions = [];
    let tempVaderBankList = [];

    if (responseJsonBank[ApiKey._API_SUCCESS_KEY]) {
      responseJsonBank[ApiKey._API_DATA_KEY].forEach(function (bank) {
        bankList.push({
          name: bank.bankName,
          id: bank.id,
        });
      });
    }

    if (responseGetVaderBankJson[ApiKey._API_SUCCESS_KEY]) {
      responseGetVaderBankJson[ApiKey._API_DATA_KEY].map((bank) => {
        if (bank.value !== "VaderPay QR")
        {
          tempVaderBankList.push({
            bankCode: bank.bankCode,
            bankId: bankList.filter(x => x.name === bank.bankName)[0].id,
            bankInstantRank: bank.bankInstantRank,
            bankLogo: bank.bankLogo,
            bankLogo2: bank.bankLogo,
            currency: "MYR",
            id: bank.id,
            maximumDeposit: bank.maximumDeposit,
            minimumDeposit: bank.minimumDeposit,
            status: true,
            name: bank.bankName
          });
        }
      });
      setVaderBankList(tempVaderBankList);
    }

    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO + "?all=true"
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data1 = [];

      if (memberJson[ApiKey._API_DATA_KEY]["vipId"] === 2)
        data1 = responseJson[ApiKey._API_DATA_KEY];
      else
        data1 = responseJson[ApiKey._API_DATA_KEY].filter((x) => !x.isVIPOnly);

      setInstantPaymentList(responseJson[ApiKey._API_DATA_KEY]);

      //instant payment load
      //data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString !== "VaderPay" && y.status === true).map((option, index) => {
      //zy testing
      //data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString !== "VaderPay").map((option, index) => {

      //  option.instantBankSettingModel.map((option2, index2) => {
      //    let foundIndex = providerOptions.findIndex(x => x.bankId === option2.bankId && x.paymentType === option.paymentType);
      //    if (foundIndex === -1) {
      //      let temProvider = [];
      //      temProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      //: option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                      //  : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                      : null,
      //      });
      //      providerOptions.push({
      //        bankId: option2.bankId,
      //        name: option2.name,
      //        status: option.status,
      //        image: option2.bankLogo2,
      //        paymentType: option.paymentType,
      //        availableProvider: temProvider,
      //        bankInstantRank: option2.bankInstantRank
      //      });
      //    } else {
      //      providerOptions[foundIndex].availableProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      : option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                        : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                      : null,
      //      });
      //    }
      //  })
      //});
      ////data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString === "VaderPay" && y.status === true).map((option, index) => {
      ////zy testing
      //data1.filter(y => y.paymentType === "Instant Payment" && y.instantPayGatewayString === "VaderPay").map((option, index) => {
      //  tempVaderBankList.map((option3, index3) => {
      //    let foundIndex = providerOptions.findIndex(x => x.bankId === option3.displayBankId && x.paymentType === option.paymentType);
      //    if (foundIndex === -1) {
      //      let temProvider = [];
      //      temProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
      //                        : option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                          : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                        : null,
      //      });

      //      providerOptions.push({
      //        bankId: option3.displayBankId,
      //        name: option3.bankName,
      //        status: option.status,
      //        image: option3.bankLogo,
      //        paymentType: option.paymentType,
      //        availableProvider: temProvider,
      //        bankInstantRank: option3.bankInstantRank
      //      });
      //    } else {
      //      providerOptions[foundIndex].availableProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
      //                        : option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                          : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                        : null,
      //      });
      //    }
      //  })
      //});

      ////duit now load
      ////data1.filter(y => y.paymentType === "DuitNow" && y.status === true).map((option, index) => {
      //// ZY Testing
      //data1.filter(y => y.paymentType === "DuitNow" && y.instantPayGatewayString !== "DirePay QR").map((option, index) => {
      //  option.instantBankSettingModel.map((option2, index2) => {
      //    if (providerOptions.filter(x => x.bankId === -100).length === 0) {
      //      let temProvider = [];
      //      temProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
      //                        : option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                          : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                            : null,
      //      });
      //      providerOptions.push({
      //        bankCode: "DuitNow",
      //        bankId: -100,
      //        name: t("DuitNow"),
      //        status: true,
      //        image: DuitNowIcon,
      //        paymentType: option.paymentType,
      //        availableProvider: temProvider,
      //        bankInstantRank: 1
      //      });
      //      //let truePayProvider = [{ name: "TruePay", image: FattyPayQRIcon }];
      //      let truePayProvider = { name: "TruePay", image: FattyPayQRIcon };
      //      let direPayProvider = { name: "DirePay QR", image: DirePayIcon };

      //      //providerOptions.push({
      //      //  bankCode: "GrabPay",
      //      //  bankId: -100,
      //      //  name: t("GRAB_PAY"),
      //      //  status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //      //  image: GrabPayIcon,
      //      //  paymentType: option.paymentType,
      //      //  availableProvider: truePayProvider,
      //      //  bankInstantRank: 2
      //      //});
      //      //providerOptions.push({
      //      //  bankCode: "MAEQR",
      //      //  bankId: -100,
      //      //  name: t("MAE_QR"),
      //      //  status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //      //  image: MAEIcon,
      //      //  paymentType: option.paymentType,
      //      //  availableProvider: truePayProvider,
      //      //  bankInstantRank: 3
      //      //});
      //      //providerOptions.push({
      //      //  bankCode: "BigPay",
      //      //  bankId: -100,
      //      //  name: t("BIG_PAY"),
      //      //  status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //      //  image: BigPayIcon,
      //      //  paymentType: option.paymentType,
      //      //  availableProvider: truePayProvider,
      //      //  bankInstantRank: 4
      //      //});
      //      //providerOptions.push({
      //      //  bankCode: "Boost",
      //      //  bankId: -100,
      //      //  name: t("BOOST_PAY"),
      //      //  status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //      //  image: BoostPayIcon,
      //      //  paymentType: option.paymentType,
      //      //  availableProvider: truePayProvider,
      //      //  bankInstantRank: 5
      //      //});

      //      //ZY Testing
      //      providerOptions.push({
      //        bankCode: "GRABPAY",
      //        bankId: -100,
      //        name: t("GRAB_PAY"),
      //        //status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //        status: (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status || data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "GRABPAY")[0] !== undefined) ? true : false,
      //        image: GrabPayIcon,
      //        paymentType: option.paymentType,
      //        //availableProvider: truePayProvider,
      //        availableProvider: (data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "GRABPAY")[0]) !== undefined && data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status ? [truePayProvider, direPayProvider] : (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status) ? [truePayProvider] : [direPayProvider],
      //        bankInstantRank: 2
      //      });
      //      providerOptions.push({
      //        bankCode: "MAEQR",
      //        bankId: -100,
      //        name: t("MAE_QR"),
      //        //status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //        status: (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status || data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].filter(x => x.name == "MAEQR")[0] !== undefined) ? true : false,
      //        image: MAEIcon,
      //        paymentType: option.paymentType,
      //        //availableProvider: truePayProvider,
      //        availableProvider: [truePayProvider],
      //        bankInstantRank: 3
      //      });
      //      providerOptions.push({
      //        bankCode: "BigPay",
      //        bankId: -100,
      //        name: t("BIG_PAY"),
      //        status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //        image: BigPayIcon,
      //        paymentType: option.paymentType,
      //        /*availableProvider: truePayProvider,*/
      //        availableProvider: (data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "BOOST")[0]) !== undefined && data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status ? [truePayProvider, direPayProvider] : (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status) ? [truePayProvider] : [direPayProvider],
      //        bankInstantRank: 4
      //      });
      //      providerOptions.push({
      //        bankCode: "BOOST",
      //        bankId: -100,
      //        name: t("BOOST_PAY"),
      //        //status: data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status,
      //        status: (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status || data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "BOOST")[0] !== undefined) ? true : false,
      //        image: BoostPayIcon,
      //        paymentType: option.paymentType,
      //        //availableProvider: truePayProvider,
      //        availableProvider: (data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "BOOST")[0]) !== undefined && data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status ? [truePayProvider, direPayProvider] : (data1.filter(x => x.instantPayGatewayString == "TruePay")[0].status) ? [truePayProvider] : [direPayProvider],
      //        bankInstantRank: 5
      //      });
      //      providerOptions.push({
      //        bankCode: "SHOPEEPAY",
      //        bankId: -100,
      //        name: t("SHOPEE_PAY"),
      //        status: data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "ShopeePay")[0] !== undefined ? true : false,
      //        image: ShopeePayIcon,
      //        paymentType: option.paymentType,
      //        /*availableProvider: truePayProvider,*/
      //        availableProvider: [direPayProvider],
      //        bankInstantRank: 6
      //      });
      //      providerOptions.push({
      //        bankCode: "TNG",
      //        bankId: -100,
      //        name: t("TNG"),
      //        status: data1.filter(x => x.instantPayGatewayString == "DirePay QR")[0].instantBankSettingModel.filter(x => x.name == "TNG")[0] !== undefined ? true : false,
      //        image: TngIcon,
      //        paymentType: option.paymentType,
      //        /*availableProvider: truePayProvider,*/
      //        availableProvider: [direPayProvider],
      //        bankInstantRank: 7
      //      });
      //    } else {
      //      providerOptions.filter(x => x.bankId === -100)[0].availableProvider.push({
      //        name: option.instantPayGatewayString,
      //        image: option.instantPayGatewayString === "SPG" ? SPGIcon
      //          : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
      //            : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
      //              : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
      //                : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
      //                  : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
      //                    : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
      //                      : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
      //                        : option.instantPayGatewayString === "DirePay" ? DirePayIcon
      //                          : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
      //                        : null,
      //      });
      //    }
      //  })
      //});

      data1.filter(y => y.isUmStatus === false).map((option, index) => {
        if (option.instantPayGatewayString == "VaderPay") {
          option.instantBankSettingModel = tempVaderBankList;
        }

        option.instantBankSettingModel.map((option2, index2) => {
          let foundIndex = providerOptions.findIndex(x => x.bankId === option2.bankId && x.paymentType === option.paymentType);
          if (foundIndex === -1) {
            let temProvider = [];
            temProvider.push({
              name: option.instantPayGatewayString,
              image: option.instantPayGatewayString === "SPG" ? SPGIcon
                : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
                  : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
                    : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
                      : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
                        : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
                          : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
                            : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
                              : option.instantPayGatewayString === "DirePay" ? DirePayIcon
                                : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
                                  : option.instantPayGatewayString === "Eeziepay" ? EeziePayIcon
                                    : option.instantPayGatewayString === "Eeziepay QR" ? EeziePayQRIcon
                                      : option.instantPayGatewayString === "WinPay" ? FattyPayIcon
                                        : null,
            });
            providerOptions.push({
              bankId: option2.bankId,
              name: option2.name,
              status: option2.status,
              image: option2.bankLogo2,
              paymentType: option.paymentType,
              availableProvider: temProvider,
              bankInstantRank: option2.bankInstantRank
            });
          } else {
            providerOptions[foundIndex].availableProvider.push({
              name: option.instantPayGatewayString,
              image: option.instantPayGatewayString === "SPG" ? SPGIcon
                : option.instantPayGatewayString === "SPG QR" ? SPGQRIcon
                  : option.instantPayGatewayString === "VaderPay" ? VaderPayIcon
                    : option.instantPayGatewayString === "VaderPay QR" ? VaderPayQRIcon
                      : option.instantPayGatewayString === "FattyPay" ? FattyPayIcon
                        : option.instantPayGatewayString === "TruePay" ? FattyPayQRIcon
                          : option.instantPayGatewayString === "PowerPay88" ? PowerPayIcon
                            : option.instantPayGatewayString === "PayTrust" ? PayTrustIcon
                              : option.instantPayGatewayString === "DirePay" ? DirePayIcon
                                : option.instantPayGatewayString === "DirePay QR" ? DirePayIcon
                                  : option.instantPayGatewayString === "Eeziepay" ? EeziePayIcon
                                    : option.instantPayGatewayString === "Eeziepay QR" ? EeziePayQRIcon
                                      : option.instantPayGatewayString === "WinPay" ? FattyPayIcon
                                        : null,
            });
          }
        })
      });

      setPaymentOptionList(providerOptions.sort((a, b) => a.bankInstantRank - b.bankInstantRank));
    }
    setLoadingPaymentData(false);
  }

  //const initMemberTransactionHistoryData = async () => {
  //  let found = props.memberTransactionHistory.filter(x => x.isInstant && x.transactionTypeId === TransactionType._DEPOSIT);
  //  if (found.length > 0) {
  //    if (instantPaymentList.filter((x) => x.instantPayGatewayString === found[0].instantProviderName)[0].paymentType === "DuitNow") {
  //      setBankIsSelected(true);
  //      setSelectedBank(paymentOptionList.filter(x => x.bankId === -100)[0]);
  //      handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === -100)[0]);
  //    } else {
  //      if (found[0].instantProviderName === "VaderPay") {
  //        if (paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId).length > 0) {
  //          setBankIsSelected(true);
  //          setSelectedBank(paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId)[0]);
  //          handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(y => y.bankId === vaderBankList.filter(x => x.bankCode === found[0].bankCode)[0].displayBankId)[0]);
  //        }
  //      } else {
  //        if (paymentOptionList.filter(x => x.bankId === found[0].instantBankId).length > 0) {
  //          setBankIsSelected(true);
  //          setSelectedBank(paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
  //          handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
  //        }
  //      }
  //    }
  //  }
  //}

  const initMemberTransactionHistoryData = async () => {
    let found = props.memberTransactionHistory.filter(x => x.isInstant && x.transactionTypeId === TransactionType._DEPOSIT);
    if (found.length > 0) {
      if (paymentOptionList.filter(x => x.bankId === found[0].instantBankId).length > 0) {
        setBankIsSelected(true);
        setSelectedBank(paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
        handleProviderSelection(found[0].instantProviderName, paymentOptionList.filter(x => x.bankId === found[0].instantBankId)[0]);
      }
    }
  }

  function handleProviderSelection(providerName, bank) {
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === providerName);
    if (index !== -1) {
      if (instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId).length > 0) {
        setMin(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].minimumDeposit);
        setMax(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].maximumDeposit);
        setServiceCharge(instantPaymentList[index]["serviceCharge"]);
        setSelectedProviderName(providerName);
      }
    }
  }

  ////other fucntion
  //function handleProviderSelection(providerName, bank) {
  //  let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === providerName);
  //  if (index !== -1) {
  //    //ZY Testing
  //    if (instantPaymentList[index].paymentType === "DuitNow" && providerName === "DirePay QR") {
  //      setSelectedBankCode(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.name === bank.bankCode)[0].name);
  //      setMin(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.name === bank.bankCode)[0].minimumDeposit);
  //      setMax(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.name === bank.bankCode)[0].maximumDeposit);
  //      setServiceCharge(instantPaymentList[index]["serviceCharge"]);
  //      setSelectedProviderName(providerName);
  //    } else
  //      if (instantPaymentList[index].paymentType === "DuitNow") {
  //        setSelectedBankCode(instantPaymentList[index]["instantBankSettingModel"][0].bankCode);
  //        setMin(instantPaymentList[index]["instantBankSettingModel"][0].minimumDeposit);
  //        setMax(instantPaymentList[index]["instantBankSettingModel"][0].maximumDeposit);
  //        setServiceCharge(instantPaymentList[index]["serviceCharge"]);
  //        setSelectedProviderName(providerName);
  //    } else {
  //        if (providerName === "VaderPay" || providerName === "VaderPay QR") {
  //        setSelectedBankCode(vaderBankList.filter(x => x.displayBankId == bank.bankId)[0].bankCode);
  //        setMin(instantPaymentList[index]["instantBankSettingModel"][0].minimumDeposit);
  //        setMax(instantPaymentList[index]["instantBankSettingModel"][0].maximumDeposit);
  //        setServiceCharge(instantPaymentList[index]["serviceCharge"]);
  //        setSelectedProviderName(providerName);
  //      } else {
  //        if (instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId).length > 0) {
  //          setSelectedBankCode(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].bankCode);
  //          setMin(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].minimumDeposit);
  //          setMax(instantPaymentList[index]["instantBankSettingModel"].filter(x => x.bankId === bank.bankId)[0].maximumDeposit);
  //          setServiceCharge(instantPaymentList[index]["serviceCharge"]);
  //          setSelectedProviderName(providerName);
  //        }
  //      }
  //    }
  //  }
  //  resetAmountValue();
  //}

  const handleBankSelection = async (bank) => {
    setBankIsSelected(true);
    setSelectedBank(bank);
    if (paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider.length > 0) {
      handleProviderSelection(paymentOptionList.filter(x => x === bank && x.status === true)[0].availableProvider[0].name, bank);
    }
  }

  function resetFormField() {
    setSelectedProviderName("");
    setBankIsSelected(false);
    setSelectedBank({});
    setSelectedBankCode("");
    setMin(0);
    setMax(0);
    setServiceCharge(0);
    resetAmountValue();
  }


    const checkValueOnSelectFixedAmt = async (fixedAmt) => {
        let updatedValue = (parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : parseFloat(amountValue)));
        updatedValue = Math.trunc(updatedValue * 100) / 100;
        if (updatedValue > max) {
            setAmountValue(
                max + ".00"
            );
            setDisplayAmountValue(max + ".00");
        }
        else {
            setAmountValue(
                updatedValue + ".00"
            );
            setDisplayAmountValue(updatedValue + ".00");
        }

        clearErrors();
        let tempAmountValue = updatedValue;
        if (parseFloat(tempAmountValue) < min) {
            setError("amount", {
                message: "VALUE_LESS_THAN_MINIMUM",
            });
        }
        else if (parseFloat(tempAmountValue) > max) {
            setError("amount", {
                message: "EXCEED_MAXIMUM_VALUE",
            });
        }
    }


  //const checkValueOnSelectFixedAmt = async (fixedAmt) => {
  //  let updatedValue = parseFloat(fixedAmt) + parseFloat(stringIsNullOrEmpty(amountValue) ? 0 : amountValue);
  //  setAmountValue(
  //    updatedValue
  //  );
  //  setDisplayAmountValue(updatedValue + ".00");
  //  clearErrors();
  //  let tempAmountValue = updatedValue;
  //  if (parseFloat(tempAmountValue) < min) {
  //    setError("amount", {
  //      message: "VALUE_LESS_THAN_MINIMUM",
  //    });
  //  }
  //  else if (parseFloat(tempAmountValue) > max) {
  //    setError("amount", {
  //      message: "EXCEED_MAXIMUM_VALUE",
  //    });
  //  }
  //}

  async function onSubmit(data, e) {
    _dispatch(setBusy());
    let index = instantPaymentList.findIndex((x) => x.instantPayGatewayString === selectedProviderName);
    if (index !== -1) {
      let foundProvider = instantPaymentList[index];
      var bankData = foundProvider["instantBankSettingModel"].filter(
        (option) => option.bankId === selectedBank.bankId
      )[0];

      let params = {
        transactionTypeId: TransactionType._DEPOSIT,
        bankId: bankData.bankId,
        amount: parseFloat(amountValue),
        bankCode: bankData.bankCode,
        channel: foundProvider["instantPayGatewayId"],
        panel: PanelType._MEMBERSITE,
        instantPayBankSettingId: bankData.id,
        currency: bankData.currency,
        instantPay: true,
        receiptReference: selectedProviderName + "(" + foundProvider["instantPayGatewayString"] + ")",
        bankAccountId: bankData.bankId,
      };

      if (!stringIsNullOrEmpty(props.bonusDeposit) && props.bonusDeposit != "-1") {
        params["bonusId"] = props.bonusDeposit;
      } else if (props.bonusDeposit == "-1") {
        params["bonusId"] = "";
      }

      let responseJson = await ApiEngine.post(
        ApiUrl._API_CREATE_TRANSACTION,
        createMultiPartFormBody(params)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setBusy(true, t("PLEASE_WAIT_REDIRECTING")));

        e.target.reset();
        props.parentInit();

        if (selectedProviderName === "SPG" || selectedProviderName === "VaderPay" || selectedProviderName === "VaderPay QR") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY]["redirect_to"],
              })
            );
          } else {
            window.location.href =
              responseJson[ApiKey._API_DATA_KEY]["redirect_to"];

            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        }
        else if (selectedProviderName === "DirePay QR" || selectedProviderName === "DirePay") {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY]["pay_url"],
              })
            );
          } else {
            window.location.href =
              responseJson[ApiKey._API_DATA_KEY]["pay_url"];

            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
        }
        else if (selectedProviderName === "Eeziepay QR" || selectedProviderName === "Eeziepay") {
          if (window.ReactNativeWebView) {
            const blob = new Blob([responseJson[ApiKey._API_DATA_KEY].content], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');

            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 100);
            _dispatch(setIdle());
          } else {
            const blob = new Blob([responseJson[ApiKey._API_DATA_KEY].content], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');

            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 100);
            _dispatch(setIdle());
          }
        }
        else if (selectedProviderName === "WinPay") {
            console.log(responseJson[ApiKey._API_DATA_KEY]);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        action: "payment",
                        url: responseJson[ApiKey._API_DATA_KEY]["url"],
                    })
                );
            } else {
                window.location.href =
                    responseJson[ApiKey._API_DATA_KEY]["url"];

                setTimeout(() => {
                    _dispatch(setIdle());
                }, 5000);
            }
        }
        else {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                action: "payment",
                url: responseJson[ApiKey._API_DATA_KEY],
              })
            );
          }
          else if (selectedProviderName === "DirePay QR" || selectedProviderName === "DirePay") {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  action: "payment",
                  url: responseJson[ApiKey._API_DATA_KEY]["pay_url"],
                })
              );
            } else {
              window.location.href =
                responseJson[ApiKey._API_DATA_KEY]["pay_url"];
              setTimeout(() => {
                _dispatch(setIdle());
              }, 5000);
            }
          }
          else {
            window.location.href =
              responseJson[ApiKey._API_DATA_KEY];

            setTimeout(() => {
              _dispatch(setIdle());
            }, 5000);
          }
          _dispatch(setIdle());
        }

      } else {
        _dispatch(
          showResponseMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            responseJson[ApiKey._API_MESSAGE_KEY]
          )
        );
      }
    }
    _dispatch(setIdle());
  }

  function onKeyPress(key) {
    if (key != "{bksp}") {
      let tempDisplayAmountValue = displayAmountValue;
        if (tempDisplayAmountValue == "0.00")
        {
            tempDisplayAmountValue = key + ".00";
        }
        else
        {
            let temp = tempDisplayAmountValue.includes(".") ? tempDisplayAmountValue.substring(0, tempDisplayAmountValue.indexOf(".")) + key + ".00" : tempDisplayAmountValue + "" + key + ".00";

            if (temp > max)
            {
                tempDisplayAmountValue = max + ".00";
            }
            else
            {
                tempDisplayAmountValue = tempDisplayAmountValue.substring(0, tempDisplayAmountValue.indexOf(".")) + key + ".00";

            }

          
        }
      setDisplayAmountValue(tempDisplayAmountValue);

      let tempAmountValue = tempDisplayAmountValue;
      if (isNaN(tempAmountValue)) {
        tempAmountValue = 0;
      }
      checkMinMax(tempAmountValue);
      setAmountValue(tempAmountValue);
    }
    else {
      resetAmountValue();
    }
  }

  function checkMinMax(amount) {
    clearErrors();
    if (parseFloat(amount) < min) {
      setError("amount", {
        message: "VALUE_LESS_THAN_MINIMUM",
      });
    }
    else if (parseFloat(amount) > max) {
      setError("amount", {
        message: "EXCEED_MAXIMUM_VALUE",
      });
    }
  }

  function resetAmountValue() {
    setAmountValue(0);
    setDisplayAmountValue("0.00");
  }

  return (
    <>
      {showCopyAlert && (
        <div style={{ position: scrollPosition < 224 ? "relative" : "sticky", top: scrollPosition < 150 ? "107px" : scrollPosition >= 150 && scrollPosition < 160 ? "-65px" : scrollPosition >= 160 && scrollPosition < 170 ? "-55px" : scrollPosition >= 170 && scrollPosition < 180 ? "-45px" : scrollPosition >= 180 && scrollPosition < 190 ? "-35px" : scrollPosition >= 190 && scrollPosition < 200 ? "-25px" : scrollPosition >= 200 && scrollPosition < 210 ? "-15px" : scrollPosition >= 210 && scrollPosition < 224 ? "-5px" : "160px", left: "-55px", width: "100%", color: "white", zIndex: "99999" }}>
          <div
            className="content content-full-width"
            style={{
              position: "absolute",
              width: "200px",
              top: "10px",
              right: "-40px"
            }}
          >
            <Alert color="green" style={{ textAlign: "center" }}>
              {t("COPY_SUCCESS")}
            </Alert>
          </div>
        </div>
      )}
      <div className="normal-deposit-wrapper">
        {!loadingPaymentData ? <form onSubmit={handleSubmit(onSubmit)}>
          {bankIsSelected === false && (
            <div className="all-normal-banks-container">
              {paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 && (
                <>
                  <div className="normal-bank-item-container">
                    <div className="font14 normal-parent-bank-title">
                      {t("BANK_INSTANT")}
                    </div>
                    <div className="deposit-normal-bank-list-container mt-3">
                      {paymentOptionList &&
                        paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).length > 0 &&
                        paymentOptionList.filter(x => x.paymentType === "Instant Payment" && x.status === true).map((child, index) => {
                          return (
                            <div key={"instant-deposit-bank" + index}
                              className="deposit-normal-bank-list-items"
                              onClick={() => { handleBankSelection(child) }}
                            >
                              <img
                                src={child.image}
                                alt="i8 banks"
                                className="img-responsive"
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </>
              )}
              {paymentOptionList.filter(x => x.paymentType === "DuitNow" && x.status === true).length > 0 && (
                <>
                  <hr className="custom-hr" />
                  <div className="normal-bank-item-container">
                    <div className="font14 normal-parent-bank-title">
                      {t("EWALLET") + " / " + t("QR_PAY")}
                    </div>
                    <div className="deposit-normal-bank-list-container mt-3">
                      {paymentOptionList &&
                        paymentOptionList.filter(x => x.paymentType === "DuitNow" && x.status === true).length > 0 &&
                        paymentOptionList.filter(x => x.paymentType === "DuitNow" && x.status === true).map((child, index) => {
                          return (
                            <div
                              key={"qr-deposit-bank" + index}
                              className="deposit-normal-bank-list-items"
                              onClick={() => { handleBankSelection(child) }}
                            >
                              <img
                                src={child.image}
                                alt="i8 banks"
                                className="img-responsive"
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {bankIsSelected && (
            <>
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("SELECTED_BANK_METHOD")}
                </div>
                <div className="second ">
                  <div className="instant-provider-selected-wrapper-orange mt-3" onClick={() => { resetFormField(); }}>
                    <div className="instant-provider active">
                      <img className="tick-image" src={TickIcon1} alt="active" />
                      <img className="instant-provider-image" src={selectedBank.image} alt={selectedBank.bankName} />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {!stringIsNullOrEmpty(selectedProviderName) ? t("SELECTED_PROVIDER") : t("PLEASE_SELECT_PROVIDER")}
                </div>
                <div className="second ">
                  <div className="instant-provider-selected-wrapper mt-3">
                    {paymentOptionList &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.length > 0 &&
                      paymentOptionList.filter(x => x === selectedBank && x.status === true)[0].availableProvider.map((child, index) => {
                        return (

                          <div key={"instant-deposit-provider" + index} className={"instant-provider " + classnames({ active: selectedProviderName === child.name, })} onClick={() => { handleProviderSelection(child.name, selectedBank); }}>
                            {selectedProviderName === child.name &&
                              <img className="tick-image" src={TickIcon} alt={child.name} />
                            }
                            <img className="instant-provider-image" src={child.image} alt={child.title} />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </>
          )}

          {bankIsSelected && selectedProviderName && (
            <>

              {parseFloat(serviceCharge) > 0 && parseFloat(serviceCharge) !== 0 && (
                <>
                  <hr className="custom-hr" />
                  <div className="normal-bank-per-section-box">
                    <div className="first">
                      {t("SERVICE_CHARGE")}
                    </div>
                    <div className="second">
                      <div className="reminder font-15 text-white font-semi">
                        {t("SERVICE_CHARGE_DISCLAIM") +
                          serviceCharge +
                          "%" +
                          t("SERVICE_CHARGE_DISCLAIM_BACK")}
                      </div>
                    </div>
                  </div>

                </>
              )}
              <hr className="custom-hr" />
              <div className="normal-bank-per-section-box">
                <div className="first">
                  {t("AMOUNT")}
                </div>
                <div className="second ">
                  <div className="form-group m-b-0">
                    <div className="deposit-input-group mt-3">
                      <span className="currency-prefix">MYR</span>
                      <input
                        type="number"
                        readOnly
                        className="form-control bg-white amount-text"
                        //   placeholder={t("PLEASE_ENTER_AMOUNT")}
                        placeholder={amountValue}
                        value={amountValue}
                        name="amount"
                        style={{ fontSize: "14px", color: "#002e6c", fontWeight: "bold" }}
                        onClick={(e) => {
                          //setCurrentInput(e.target);
                          setShowNumpad(true);
                        }}
                        ref={register({
                          required: "PLEASE_ENTER_AMOUNT",
                          validate: {
                            minValue: (value) =>
                              (!canDisplayBank
                                ? value >= min
                                : parseFloat(value) > 0 && value >= min) ||
                              "VALUE_LESS_THAN_MINIMUM",
                            maxValue: (value) =>
                              (!canDisplayBank ? true : parseFloat(value) <= max) ||
                              "EXCEED_MAXIMUM_VALUE",
                          },
                        })}
                      />

                      <div className="close-button">
                        <Icon
                          path={mdiClose}
                          size={1}
                          color="#FF0808"
                          onClick={() => {
                            resetAmountValue();
                          }}
                        />
                      </div>
                    </div>
                    {errors.amount && (
                      <div className="invalid-feedback">{t(errors.amount.message)}</div>
                    )}

                    <div className="col-md-12 font-11 mt-2">
                      {parseFloat(amountValue) >= min ?
                        <>
                          <span>{t("MAIN_WALLET_NEW_BALANCE") + " : "}</span>
                          <span>{numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true) + " "}</span>
                          <i className="fas fa-arrow-right" />
                          <span>{" " + numberWithCurrencyFormat2(parseFloat(_userData.walletBalance) + (amountValue > 0 ? parseFloat(amountValue) : 0), 2, true)}</span>
                        </>
                        :
                        <>
                        </>
                      }
                    </div>

                    <div className="col-md-12  instant-provider-reminder">
                      {min >= 0 && max >= 0 && (
                        <div className="font-11 amount-limit-reminder">
                          <span className="reminder mr-2">
                            * {t("MIN")} : MYR {numberWithCommas(min)} / {t("MAX")} : MYR {numberWithCommas(max)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="amount-row">
                      {fixedAmount.map((amount, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            onClick={() => {
                              checkValueOnSelectFixedAmt(amount);
                            }}
                            className="btn custom-button"
                          >
                            + {numberWithCommas(amount)}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mt-3 col-md-12 px-0">
                              <button disabled={ amountValue < min} type="submit" className="btn btn-block btn-brand-gradient">
                  {t("SUBMIT")}
                </button>
              </div>
            </>
          )}
        </form> :
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status" size="sm"></Spinner>
          </div>}
      </div>
      {/*<DepositNumpad
        currentBalance={_userData.walletBalance}
        currentInput={currentInput}
        setCurrentInput={(e) => {
          console.log(currentInput.value);
          setAmountValue(parseFloat(currentInput.value));
          setCurrentInput(e);
        }}
      />*/}
      <Modal fade={false} contentClassName="modal-brand modal-bottom modal-numpad  popup-transfer-numpad" backdrop={true} toggle={() => { setShowNumpad(false); }} isOpen={showNumpad} centered>
        <ModalBody>
          <div className="display-wrapper">
            <button type="button" style={{
              alignSelf: "flex-end", backgroundColor: "transparent", border: "none", marginRight: "15px", fontSize: "2rem", color: "#002e6c", marginTop: "-15px", fontWeight: "bold",
            }} onClick={() => { setShowNumpad(false); }} data-dismiss="modal" aria-hidden="true"><span>&times;</span></button>
            <strong className="text-brand display-value">{displayAmountValue}</strong>

            <div className="text-brand text-center" style={{ marginTop: "10px" }}>{t("CURRENT_BALANCE")} {isNaN(parseFloat(_userData.walletBalance)) ? "0.00" : numberWithCurrencyFormat2(parseFloat(_userData.walletBalance), 2, true)/*parseFloat(props.currentBalance).toFixed(3)*/}</div>
          </div>
          <Keyboard
            layout={{
              'default': [
                "1 2 3 4 5 6 7 8 9 0 {bksp}"
              ]
            }}
            display={{
              '{bksp}': "<i class='fas fa-backspace'></i>",
            }}
            theme={`keyboard keyboard-numpad keyboard-numpad-v3`}
            keyboardRef={r => (keyboard.current = r)}
            onKeyPress={onKeyPress}
            disableButtonHold
            disableCaretPositioning
          />

                  <button disabled={ amountValue < min } className="btn btn-block btn-brand-gradient btn-done" onClick={() => { setShowNumpad(false); }}>{t("DONE")}</button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InstantDeposit;